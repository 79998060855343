
import { defineComponent, onBeforeMount, reactive } from 'vue'

import SButton from '@/common/components/SButton/index.vue'
import SLoader from '@/common/components/SLoader/index.vue'

import { useRoute } from 'vue-router'
import { FTLRealisation } from '@/core/realisations/FTL.realisation'
import { FTLTTNI } from '@/core/types/FTL.types'
import useNotifications from '@/common/composable/useNotifications'

const NOTIFICATIONS_GROUP = 'create-module'

export default defineComponent({
    components: {
        's-button': SButton,
        's-loader': SLoader,
    },
    setup() {
        const route = useRoute()
        const FTL = new FTLRealisation()
        const notifications = useNotifications()

        const state = reactive({
            is_loading: false,
            is_failed: true,
            ttn: null as null | FTLTTNI,
        })

        const ttn_id = Number(route.params.order_id as string)

        const loadTTN = async () => {
            try {
                state.is_loading = true

                const response = await FTL.printTTN(ttn_id)
                state.ttn = response.data

                setTimeout(window.print, 500)

                state.is_failed = false
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка получения данных ТТН',
                    },
                    error
                )

                state.is_failed = true
            } finally {
                state.is_loading = false
            }
        }

        onBeforeMount(loadTTN)

        return { state, loadTTN }
    },
})
